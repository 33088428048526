import { Creators } from '@/assets/data/demo1.js';

const Sort_select_list = [
    { id: 1, text: 'Sort: Product Views (Descending)', keyword: 'views' },
    { id: 2, text: 'Sort: Product Views (Ascending)', keyword: 'views' },
    { id: 3, text: 'Sort: Clicks (Descending)', keyword: 'click' },
    { id: 4, text: 'Sort: Clicks (Ascending)', keyword: 'click' },
    { id: 5, text: 'Sort: Sales (Descending)', keyword: 'sales' },
    { id: 6, text: 'Sort: Sales (Ascending)', keyword: 'sales' },
    { id: 7, text: 'Sort: Sales Value (Descending)', keyword: 'sales_value' },
    { id: 8, text: 'Sort: Sales Value (Ascending)', keyword: 'sales_value' },
];

const Sort_Type_list = [
    { id: 0, text: 'Type: All', keyword: '' },
    { id: 1, text: 'Type: Active', keyword: 'active',field_name:'collection_status' },
    { id: 2, text: 'Type: Featuring', keyword: 'featuring',field_name:'collection_status' },
    { id: 3, text: 'Type: Deleted', keyword: 'deleted',field_name:'collection_status' },
    { id: 4, text: 'Type: Tiktok', keyword: 'tiktok',field_name:'collection_type'  },
    { id: 5, text: 'Type: Instagram', keyword: 'instagram',field_name:'collection_type'  },
    { id: 6, text: 'Type: Standard', keyword: 'collection',field_name:'collection_type'  },
];

const Sort_Status_list = [
    { id: 0, text: 'Status: All', keyword: '' },
    { id: 1, text: 'Status: Active', keyword: 'active' },
    { id: 2, text: 'Status: Disabled', keyword: 'disabled' },
];

let Sort_select_coll_list = [];

Sort_select_list.forEach(e => {
    Sort_select_coll_list.push({
        ...e,
        id: e.id === 3 ? 11 : e.id === 4 ? 12 : e.id,
        keyword: e.id === 3 || e.id === 4 ? 'product_click' : e.keyword,
        text: e.id === 3 ? 'Sort: Product clicks (Descending)' : e.id === 4 ? 'Sort: Product clicks (Ascending)' : e.text
    });

    if (e.id === 2) {
        Sort_select_coll_list.push({ id: 13, text: 'Sort: Visit (Descending)', keyword: 'visit' });
        Sort_select_coll_list.push({ id: 14, text: 'Sort: Visit (Ascending)', keyword: 'visit' });
    }
});



const Sort_select_coll_pod_list = JSON.parse(JSON.stringify(Sort_select_list)).map(e => {
    return {
        ...e,
        id: e.id === 3 ? 9 : e.id === 4 ? 10 : e.id,
        keyword: e.id === 3 ? 'clicks' : e.id === 4 ? 'clicks' : e.keyword
    }
});

const PickSortIndex = (arr, key, val) => {
    const targetIndices = [];

    for (let i = 0; i < arr.length; i++) {
        if (arr[i].keyword === key) {
            targetIndices.push(i);
        }
    }
    if (val === 'desc') {
        return targetIndices[0];
    } else {
        return targetIndices[1];
    }
}


const SwitchSort = (type) => {
    let ths_sort = {
        key: '',
        val: ''
    };
    switch (type) {
        case 1:
            ths_sort.key = 'views';
            ths_sort.val = 'desc';
            break;
        case 2:
            ths_sort.key = 'views';
            ths_sort.val = 'asc';
            break;

        case 3:
            ths_sort.key = 'click';
            ths_sort.val = 'desc';
            break;

        case 4:
            ths_sort.key = 'click';
            ths_sort.val = 'asc';
            break;

        case 5:
            ths_sort.key = 'sales';
            ths_sort.val = 'desc';
            break;
        case 6:
            ths_sort.key = 'sales';
            ths_sort.val = 'asc';
            break;

        case 7:
            ths_sort.key = 'sales_value';
            ths_sort.val = 'desc';
            break;

        case 8:
            ths_sort.key = 'sales_value';
            ths_sort.val = 'asc';
            break;

        case 9:
            ths_sort.key = 'clicks';
            ths_sort.val = 'desc';
            break;

        case 10:
            ths_sort.key = 'clicks';
            ths_sort.val = 'asc';
            break;

        case 11:
            ths_sort.key = 'product_click';
            ths_sort.val = 'desc';
            break;

        case 12:
            ths_sort.key = 'product_click';
            ths_sort.val = 'asc';
            break;

        case 13:
            ths_sort.key = 'visit';
            ths_sort.val = 'desc';
            break;

        case 14:
            ths_sort.key = 'visit';
            ths_sort.val = 'asc';
            break;

        default:
            break;
    }

    return ths_sort;
};

const ImageError = ($event) => {
    if ($event.target.src.indexOf('.webp') > -1) {
        $event.target.src = $event.target.src.replace('.webp', '.jpg');
        $event.target.onerror = () => {
            $event.target.src = require('@/assets/images/no-image.png');
        };
    }
    else
        $event.target.src = require('@/assets/images/no-image.png');
};

const HandleNum = (num) => {
    return num && num !== -1 ? Number(num).toLocaleString('en-US') : "---";
};

const HandleNumSpec = (num) => {
    return num && num === -1 ? "---" : num || num === 0 ? Number(num * 100).toLocaleString('en-US') : "---";
};

const HandleName = (name) => {
    return name ? name : "---";
};

const HandleKNum = (num) => {

    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'm';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'k';
    } else {
        return HandleNum(num);
    }
}

const handlePostCard = () => {
    return {}
}

const fakeCreator = () => {
    return Creators.find(person => person.first_name === 'Haley');
}


const objToArray = (obj) => {
    return Object.keys(obj).map(key => {
        return { key, ...obj[key] };
    });
}

const arrayToObj = (array) => {
    return array.reduce((obj, item) => {
        const { key, ...rest } = item;
        obj[key] = rest;
        return obj;
    }, {});
}

const makeCollectionBadage=(item ,vi)=>{
    item.badage = [];

    //temp
    if (item.type==='post') {

        if(item.media_type==='App\\TikTokVideo'){
            item.badage.push({ name: 'TikTok', color: 'normal' })
        }

        if(item.media_type==='App\\InstagramMedia'){
            item.badage.push({ name: 'Instagram', color: 'normal' })
        }

    }else{
        item.badage.push({ name: 'Standard', color: 'normal' })
    }

    if (item.is_feature) {
        item.badage.push({ name: 'Featuring', color: 'yellow' })
    }

    if (item.deleted_at) {
        item.badage.push({ name: `Deleted: ${vi.$filters.momentFormat(item.deleted_at, 'll')}`, color: 'red' })
    } else {
        if (item.is_publish) {
            item.badage.push({ name: 'Active', color: 'green' })
        } else {
            item.badage.push({ name: `Inactive: ${vi.$filters.momentFormat(item.updated_at, 'll')}`, color: 'normal' })
        }
    }
}

export {
    Sort_select_list,
    Sort_select_coll_list,
    Sort_select_coll_pod_list,
    SwitchSort, ImageError, HandleNum, HandleName,
    PickSortIndex,
    handlePostCard,
    HandleNumSpec,
    HandleKNum,
    fakeCreator,
    objToArray,
    arrayToObj,
    Sort_Type_list,
    Sort_Status_list,
    makeCollectionBadage,
};