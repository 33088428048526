<template>
    <Content class="collections-list-content" >
        <div class="collections">
            <div class="header" v-show="!is_loading && is_cre">
                <h3>All Collections&ensp;({{ data_total }}) </h3>
                <div class="right">
                    <DatePicker :key="date_key" @update-date="updateDate" :date_range="date_range" ref="date_picker" />
                    <Dropdown v-if="!is_loading" :items="sort_select" :direction_type="'right'" :icon="'filter'"
                        :default_value="sort_index" @update-dropdown="updateSort" :key="drop_key" />
                    <Pagenavi :disabled="data.length === 0" :page_current="sort.page" :page_total="sort.total_page"
                        @goto-page="gotoPage" />
                </div>
            </div>

            <div class="header is_cre-header" v-show="!is_loading && !is_cre" >
                <h2>All Storefront Collections&ensp;({{ data_total }}) </h2>
                <div class="flex-box">
                    <div class="middle">
                        <Search @onenter="search" @update="getSearchVal" :disabled="is_disabled" :placeholder_text="`Search by creator name or collection name`"/>
                    </div>
                    <div class="right">
                        <Dropdown v-if="!is_loading" :items="sort_type" :direction_type="'right'" :icon="'filter'" @is-open="handleOpenDrop" :type="'type'"
                            :default_value="sort.filter.default_type" @update-dropdown="updateSortType" :key="sort.filter.type_key" 
                            :handle_open_value="handle_open_value.type" :disabled="is_disabled"/>
                        <Dropdown v-if="!is_loading" :items="sort_status" :direction_type="'right'" :icon="'filter'" @is-open="handleOpenDrop" :type="'status'"
                        :default_value="sort.filter.default_status" @update-dropdown="updateStatus" :key="sort.filter.status_key" 
                        :handle_open_value="handle_open_value.status" :disabled="is_disabled"/>
                        <Dropdown v-if="!is_loading" :items="sort_select" :direction_type="'right'" :icon="'filter'"
                        :default_value="sort_index" @update-dropdown="updateSort" :key="drop_key"  @is-open="handleOpenDrop" :type="'sort'"
                        :handle_open_value="handle_open_value.sort" :disabled="is_disabled"/>
                        
                        <Pagenavi :disabled="is_disabled" :page_current="sort.page" :page_total="sort.total_page"
                                @goto-page="gotoPage" />
                    </div>
                </div>
            </div>

            <div class="filter-results" v-if="!is_cre">
                <div v-if="sort.filter.type" @click="clearFilter('type')" class="item">
                    {{sort.filter.type_text}}<SvgIcon name="close" />
                </div>

                <div v-if="this.sort.filter.status" @click="clearFilter('status')" class="item">
                    {{sort.filter.status_text}}<SvgIcon name="close" />
                </div>
            </div>

            <!-- list -->
            <div class="inner">
                <div :class="['data-wrap', data.length === 0 && 'data-wrap-no-border']">
                    <Scrollbar class="data-list" :show_bar="true">
                        <NoData v-if="is_disabled" :image="require('@/assets/images/data.png')"
                            :title="''" :text="!is_cre ? 'Creators has not made any Collections yet.':'This creator hasn’t created any Collections yet.'" />
                        <Table v-else>
                            <template #head>
                                <tr>
                                    <th class="title"><span>Collections</span></th>
                                    <th v-if="is_cre"><span>Created Date</span></th>
                                    <th v-if="is_cre"><span>Last Update</span></th>
                                    <th v-if="!is_cre"><span>Created by</span></th>
                                    <th>
                                        <CollectionTh :name="'views'" :title="'Collection Views'" :sort_value="sort"
                                            @sort="sortFun"
                                            :content="'The number of views of a Collection cover during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'visit'" :title="'Visits'" :sort_value="sort"
                                            @sort="sortFun"
                                            :content="'Total number of clicks to visit a Collection during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'product_click'" :title="'Product Clicks'"
                                            :sort_value="sort" @sort="sortFun"
                                            :content="'Cumulative link clicks on products in the Collection during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'sales'" :title="'Sales'" :sort_value="sort"
                                            @sort="sortFun"
                                            :content="'Total number of sales driven by the Collection during the time period selected.'" />
                                    </th>
                                    <th>
                                        <CollectionTh :name="'sales_value'" :title="'Sales Value'" :sort_value="sort"
                                            :content="'Gross transaction value driven by the Collection during the time period selected.'"
                                            @sort="sortFun" />
                                    </th>
                                </tr>
                            </template>

                            <template #body>
                                <template v-if="!is_loading && data.length === 0 && this.isFilter">
                                    <tr>
                                        <td colspan="13">
                                            <NoData />
                                        </td>
                                    </tr>
                                </template>

                                <tr v-else v-for="(row, index) in data" :key="index"
                                    :class="{ 'selected': row.is_selected }" @click="open(row)">

                                    <td class="title">
                                        <div class="title-content">

                                            <figure class="img-box img-box-white" v-if="row.cover_image_url">
                                                <img class="likeboard-layout-wrap" :src="row.cover_image_url"
                                                    :alt="row.name" @error="imageError($event)" />
                                            </figure>

                                            <figure class="img-box" v-else>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[0]"
                                                        :src="row.first_4_product_thumbnails[0]" :alt="row.name" /></i>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[1]"
                                                        :src="row.first_4_product_thumbnails[1]" :alt="row.name" /></i>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[2]"
                                                        :src="row.first_4_product_thumbnails[2]" :alt="row.name" /></i>
                                                <i><img v-if="row.first_4_product_thumbnails && row.first_4_product_thumbnails[3]"
                                                        :src="row.first_4_product_thumbnails[3]" :alt="row.name" /></i>
                                            </figure>

                                            <div class="middle">
                                                <div class="collect-name-box">
                                                    <div class="collect-name"
                                                        v-tippy="{ content: row.name, delay: [500, 20] }">
                                                        {{ row.name }}</div>
                                                </div>

                                                <div class="collect-date-box" v-if="!is_cre">
                                                    <div class="collect-date"
                                                        v-tippy="{ content: `Created by ${ row.created_at ? row.created_at : '---'}`, delay: [500, 20] }">
                                                        Created by {{ row.created_at ? row.created_at : '---'}}</div>
                                                    <div class="collect-date"
                                                        v-tippy="{ content: `${ row.updated_at ? row.updated_at : '---' } (last update)`, delay: [500, 20] }">
                                                        {{ row.updated_at ? row.updated_at : '---' }} (last update) </div>
                                                </div>

                                                <div class="shop">
                                                    <SvgIcon name="bag" />{{ row.product_count ? row.product_count : 0
                                                    }}
                                                </div>
                                                <div class="badage-box">
                                                    <Badage v-for="(v, k) in row.badage" :key="k" :value="v.name"
                                                        :type="v.color" />
                                                </div>
                                            </div>
                                            <div class="right" @click.stop="goOutside(row)">
                                                <SvgIcon name="go-out" v-if="row.is_publish && !row.deleted_at" />
                                            </div>
                                        </div>
                                    </td>

                                    <td v-if="is_cre"><span v-if="row.created_at">{{ row.created_at ? row.created_at : '---' }}</span>
                                    </td>
                                    <td v-if="is_cre"><span v-if="row.updated_at">{{ row.updated_at ? row.updated_at : '---' }}</span>
                                    </td>

                                    <td class="cre-name-box" v-if="!is_cre">
                                        <div class="collect-cre-box">
                                            <figure class="img-box">
                                                <img :src="row.userProfile.avatar_full_url"
                                                    :alt="row.user_name" @error="imageError($event)" />
                                            </figure>
                                            <div class="collect-name"
                                                v-tippy="{ content:row.user_name, delay: [500, 20] }">
                                                {{row.user_name }}</div>
                                        </div>
                                        <tippy :delay="[0, 20]" class="storefront-state"
                                            :class="['class-' + row.anchor_creator.anchor_page_status]" :key="row.anchor_creator.anchor_page_status">
                                            {{ row.anchor_creator.anchor_page_status_text }}
                                        </tippy>
                                    </td>

                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.views }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.visit }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.product_click }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.sales }}</span>
                                        </div>
                                    </td>
                                    <td class="number">
                                        <div class="value">
                                            <span>{{ row.sales_value }}</span>
                                        </div>
                                    </td>
                                </tr>

                                <td colspan="13" v-if="is_next_loading || is_loading" >
                                    <Loading class="static" />
                                </td>
                            </template>
                        </Table>
                    </Scrollbar>
                </div>
            </div>
        </div>
    </Content>
</template>

<script>

import Content from '@/components/ui/Content.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import Table from '@/components/ui/Table.vue';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Loading from '@/components/ui/Loading.vue';
import NoData from '@/components/creator/NoData.vue';
import moment from 'moment';
import Layout from '@/components/creator/CollectionImage.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import CollectionProduct from '@/components/creator/CollectionProduct.vue';
import Badage from '@/components/ui/Badage.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import CollectionTh from '@/components/creator/CollectionProductTh.vue';
import Search from '@/components/ui/Search.vue';
import Pagenavi from '@/components/ui/Pagenavi.vue';
import { Sort_select_coll_list, SwitchSort, ImageError, HandleNum, HandleName, PickSortIndex, Sort_Type_list,Sort_Status_list, makeCollectionBadage } from '@/utils/SwitchFun';

export default {
    name: 'Collections-List',

    data() {
        return {
            is_loading: true,
            date_key: 0,
            start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),
            end_date: moment().add(-1, 'days').format('YYYY-MM-DD'),
            isFilter: false,
            data: [],
            data_total: 0,
            sort: {
                sort_key: 'views',
                sort_val: 'desc',
                filter:{
                    type:'',
                    type_text:'',
                    default_type:0,
                    type_key:0,
                    type_field:'',
                    //
                    status:'',
                    status_text:'',
                    default_status:0,
                    status_key:0,
                },
                page: 1,
                per_page: 50,
                total_page:1,
            },
            sort_select: Sort_select_coll_list,
            sort_type:Sort_Type_list,
            sort_status:Sort_Status_list,
            sort_index: 0,
            sort_type_index:0,
            drop_key: 0,
            drop_type_key:0,
            is_end: false,
            is_next_loading: false,
            search_keyword:'',
            drop_open:{
                type:false,
                status:false,
                sort:false
            },
        }
    },

    props: {
        date_range: {
            type: Object,
            default: null,
        },
        data_info: {
            type: Object,
            default: null,
        },
        is_show_hint: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
    },

    components: {
        Content,
        Scrollbar,
        Table,
        NoData,
        Loading,
        SvgIcon,
        Layout,
        DatePicker,
        CollectionProduct,
        Badage,
        Dropdown,
        CollectionTh,
        Search,
        Pagenavi,
    },

    watch: {
        '$route.params': {
            handler() {
                this.data = [];
                this.is_loading = true;
            },
            immediate: true,
        }
    },

    computed:{
        is_cre(){
            return this.data_info && this.data_info.id;
        },

        handle_open_value(){
            return{
                type:this.drop_open.sort || this.drop_open.status,
                status:this.drop_open.type || this.drop_open.sort,
                sort:this.drop_open.type ||this.drop_open.status,
            }
        },

        is_disabled(){
            return this.data.length === 0 && !this.isFilter && !this.is_loading;
        },
    },

    methods: {

        open(row) {
            this.EventBus.emit('open-collect-product-modal', { data: row, data_info: this.data_info, date_range: this.date_range, is_show_hint: this.is_show_hint });
        },

        imageError: ImageError,
        handleNum: HandleNum,
        handleName: HandleName,

        gotoPage(page) {
            this.sort.page = page;
            this.fetchData();
        },

        makeApiStr(){
            let filters=[];

            if(this.sort.filter.type){
                filters.push({"field_name": this.sort.filter.type_field, "value": this.sort.filter.type})
            } 

            if(this.sort.filter.status){
                filters.push({"field_name": "anchor_status", "value": this.sort.filter.status})
            } 

            if(this.search_keyword){
                filters.push({"field_name": "keyword", "value": this.search_keyword })
            } 
            
            return JSON.stringify(filters);
        },

        fetchData() {
            this.is_loading = true;
            let vi = this;
            let req_par = {
                start_date: this.date_range.start_date,
                end_date: this.date_range.end_date,
            }
            if(this.data_info){
                req_par.anchor_creator_id=this.data_info.id
            }
           
            let params_data = {
                ...req_par,
                sort_key: this.sort.sort_key,
                sort_val: this.sort.sort_val,
                page: this.sort.page,
                per_page: this.sort.per_page,
                filters: this.makeApiStr(),
            };

            //call table api
            this.$ajax(`creator/get-collection-list`, {
                params: params_data,
                method: 'get',
            }).then(res => {

                res.data.data.data.forEach(item => {
                    item.created_at = vi.$filters.momentFormat(item.created_at, 'll');
                    item.updated_at = vi.$filters.momentFormat(item.updated_at, 'll');
                    item.views = vi.handleNum(item.views);
                    item.visit = vi.handleNum(item.visit);
                    item.product_click = vi.handleNum(item.product_click);
                    item.sales = vi.handleNum(item.sales);
                    item.sales_value = vi.handleNum(item.sales_value);
                    item.cover_image_url = item.cover_image_url ? item.cover_image_url : !item.cover_image_url && item.first_4_product_thumbnails && item.first_4_product_thumbnails.length === 0 ? item.default_cover_image_url : null;
                    
                    if (!item.userProfile) {
                        item.userProfile = {
                            avatar_full_url: require('@/assets/images/user.png')
                        }
                    }

                    if (item.anchor_creator) {
                        item.user_name=`${item.anchor_creator.first_name} ${item.anchor_creator.last_name}`
                    }
                    
                    makeCollectionBadage(item, vi);

                });

                this.data = res.data.data.data;
                
                this.data_total = res.data.data.total;
                this.sort.total_page = res.data.data.last_page;

                this.is_end = res.data.data.current_page === res.data.data.last_page;

                this.is_loading = false;
                this.is_next_loading = false;
            });

            //call total api(not useing now)
            // this.$ajax(`/creator/get-collection-list-statistic`, {
            //     params: req_par,
            //     method: 'get',
            // }).then(res => {
            //     let data = res.data.data[0] ? res.data.data[0] : null;
            // });
        },

        updateSort(id) {
            let getVal = SwitchSort(id.id);
            this.sort.sort_key = getVal.key;
            this.sort.sort_val = getVal.val;
            this.sort.page =1;

            this.sort_index = PickSortIndex(this.sort_select, this.sort.sort_key, this.sort.sort_val);
            this.drop_key += 1;
            this.fetchData();
        },

        updateSortType(val){
            console.log('val',val)
            this.sort.filter.type=val.keyword;
            this.sort.filter.type_text=val.text;
            this.sort.filter.default_type= val.id;
            this.sort.filter.type_field= val.field_name;
            this.sort.filter.type_key+=1;
            this.sort.page =1;
            this.isFilterFun();
            this.fetchData();
        },

        updateStatus(val){
            console.log('val',val)
            this.sort.filter.status = val.keyword;
            this.sort.filter.status_text=val.text;
            this.sort.filter.default_status = val.id;
            this.sort.filter.status_key+=1;
            this.sort.page =1;
            this.isFilterFun();
            this.fetchData();
        },

        isFilterFun(){
            this.isFilter= this.sort.filter.default_status !==0 || this.sort.filter.default_type !== 0 || this.search_keyword;
            console.log('this.isFilter',this.isFilter)
        },

        clearFilter(val){
            if(val==='type'){
                this.updateSortType({keyword:'',id:0});
            }

            if(val==='status'){
                this.updateStatus({keyword:'',id:0});
            }
            this.sort.page =1;
        },

        sortFun(key) {
            if (key === this.sort.sort_key) {
                this.sort.sort_val = this.sort.sort_val === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort.sort_key = key;
                this.sort.sort_val = 'asc';
            }

            //(sort the table can chnage dropdown)
            this.sort.page =1;
            this.sort_index = PickSortIndex(this.sort_select, this.sort.sort_key, this.sort.sort_val);
            this.drop_key += 1;
            this.fetchData();
        },

        updateDate(obj) {
            this.$emit('update-creator-modal-date', obj);
        },

        goOutside(row) {
            if (row.is_publish && !row.deleted_at) {
                window.open(row.share_url, '_blank');
            }
        },

        search(keyword){
            this.search_keyword = keyword;
            this.sort.page =1;
            this.isFilterFun();
            this.fetchData();
        },

        getSearchVal(keyword){
            this.search_keyword = keyword;
        },

        handleOpenDrop(val){
            this.drop_open[val.type]=val.value;
        }

    },

    mounted() {
        if (this.date_range) {
            this.date_key += 1;
            this.sort.page =1;
            this.fetchData();
            console.log('collectionList-call-api')
        }
    },

}
</script>

<style lang="scss">
@mixin textOver() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}

.collections-list-content {

    .header {
        font: var(--font-demi-16);
        line-height: 32px;
        color: var(--black-90);
        display: flex;
        align-items: center;
        margin: 16px 0;

        h3 {
            flex: 1;
        }

        .right {
            display: flex;
            align-items: center;

            .ui-date-picker {
                margin-right: 10px;
            }
        }

    &.is_cre-header{
        flex-wrap: wrap;

        .flex-box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 8px;
        }
        h2{
            font: var(--font-demi-24);
            color: var(--black-90);
            margin: 0;
            display: block;
            width: 100%;
        }

        .middle{
            flex:1;

            .ui-search-input .input {
                max-width: unset;
            }
        }

        .right{
            .ui-dropdown{
                margin-left: 8px;
            }
        }
    }
        .ui-pagenavi{
            margin-left: 8px;
        }

    }

    &>.inner {
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 100%;
        overflow: hidden;
    }

    .loading.static {
        margin-top: 48px;
        width: 100%;
    }

    .data-wrap {
        margin: 16px 0;
        border-top: 1px solid var(--border);
        // min-height: 600px;
        height: calc(100% - 16px);
        overflow: hidden;
        display: grid;
        grid-template-rows: auto auto 1fr;
        grid-template-columns: 100%;

        &.data-wrap-no-border {
            border: none;
        }

        .ui-table {
            white-space: nowrap;

            .svg-icon {
                width: 14px;
                height: 14px;
                cursor: pointer;
            }

            th {
                color: var(--black-75);
                background-color: unset;

                .title-content {
                    max-width: 380px;
                    min-width: 380px;
                }

                .ico {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            td {
                color: var(--black-75);
                cursor: pointer;


                .title-content {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    max-width: 380px;
                    overflow: hidden;

                    .img-box {
                        position: relative;
                        width: 160px;
                        height: 160px;
                        min-width: 160px;
                        background-color: var(--bg-03);
                        border-radius: 2px;

                        &.img-box-white {
                            background-color: var(--bg-01);
                        }

                        .likeboard-layout-wrap {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            margin-right: 11px;
                        }

                        i {
                            width: 50%;
                            height: 50%;
                            display: block;
                            position: absolute;
                            box-sizing: border-box;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                display: block;
                            }

                            &:nth-child(1) {
                                top: 0;
                                left: 0;
                                border-bottom: 1px solid var(--bg-01);
                                border-right: 1px solid var(--bg-01);
                            }

                            &:nth-child(2) {
                                top: 0;
                                right: 0;
                                border-bottom: 1px solid var(--bg-01);
                            }

                            &:nth-child(3) {
                                bottom: 0;
                                left: 0;
                                border-right: 1px solid var(--bg-01);
                            }

                            &:nth-child(4) {
                                bottom: 0;
                                right: 0;
                            }
                        }
                    }


                    .badage-box {
                        display: flex;
                        flex-wrap: wrap;

                        .my-badges-iden {
                            margin-right: 8px;
                            margin-bottom: 4px;
                        }
                    }


                    .middle {
                        overflow: hidden;
                        width: calc(100% - 198px);
                        font-weight: 400;
                        color: var(--black-90);

                        .collect-name-box {
                            width: 100%;
                            overflow: hidden;
                        }

                        .collect-name {
                            width: 100%;
                            font: var(--font-demi-14);
                            margin-right: 11px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            white-space: break-spaces;
                        }

                        .shop {
                            display: flex;
                            align-items: center;
                            margin: 8px 0px;
                            font: var(--font-med-12);
                        }

                        .collect-date-box{
                            font-size:10px;
                            margin-top: 6px;
                            color:var(--black-75);
                        }
                    }

                    .right {
                        width: 14px;

                        .svg-icon {
                            color: var(--blue);
                        }
                    }

                }

                &.number {
                    .value {
                        display: flex;
                        align-items: center;
                    }

                    .svg-icon {
                        margin-left: 10px;
                        fill: var(--green);

                        &.down {
                            fill: var(--red);
                            transform: rotate(90deg)
                        }
                    }
                }
            }

            .cre-name-box{
                max-width:150px;

                .collect-cre-box{
                    display: flex;
                    align-items:center;

                    .img-box{
                        width: 24px;
                        height: 24px;
                        overflow: hidden;
                        border-radius: 50%;
                        margin-right:6px;

                        img {
                            object-fit: contain;
                            width: 100%;
                        }

                    }

                }

                .storefront-state{
                    margin-left: 30px;
                }
            }
        }

    }

}
</style>